'use client';

import {type FC, useCallback, useState} from 'react';
import clsx from 'clsx';

import {Button} from '@/components/button';
import type {ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';
import type {KeyedArray} from '@/shared/types';

import type {Article} from '../../shared/types';
import {ArticleCard} from '../article-card';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	limit?: number;
	articles: KeyedArray<Article>;
}

const ARTICLES_LENGTH = 6;

const ArticleListPaginated: FC<Props> = ({spacingBottom, spacingTop, limit, articles}) => {
	const [visibleArticles, setVisibleArticles] = useState<KeyedArray<Article>>(
		articles.slice(0, ARTICLES_LENGTH),
	);

	const loadMore = useCallback(() => {
		const nextVisibleArticles = articles.slice(0, visibleArticles?.length + ARTICLES_LENGTH);
		setVisibleArticles(nextVisibleArticles);
	}, [articles, visibleArticles]);

	if (!visibleArticles) return null;

	const totalArticles = limit || articles?.length;

	const hasMore = visibleArticles.length < totalArticles;

	let renderedArticles = visibleArticles;

	renderedArticles = limit && limit > 0 ? visibleArticles.slice(0, limit) : visibleArticles;

	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<ul
				className={clsx(
					'grid',
					'auto-rows-fr',
					'grid-cols-1',
					'lg:grid-cols-3',
					'gap-x-4',
					'gap-y-8',
				)}
			>
				{renderedArticles && renderedArticles.length && (
					<>
						{renderedArticles.map((article) => {
							if (!article) {
								return null;
							}

							return (
								<li key={article._key}>
									<ArticleCard article={article} headingLevel={2} />
								</li>
							);
						})}
					</>
				)}
			</ul>
			{hasMore && (
				<Button
					size="small"
					className={clsx('justify-self-center', 'mt-8')}
					onClick={loadMore}
				>
					Vis flere artikler
				</Button>
			)}
		</Container>
	);
};

export default ArticleListPaginated;
