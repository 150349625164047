import {groq} from 'next-sanity';

import type {KeyedArray} from '@/shared/types';

import type {Project} from '../../shared/types';
import type {ProjectInCardFragment} from '../query';
import {PROJECT_IN_CARD_FRAGMENT, projectInCardFragmentToProjectDetails} from '../query';
import {project} from '../schema';

import type {ProjectListAllSchema} from './schema';

export const PROJECT_LIST_ALL_FRAGMENT = groq`
	...,
	"projects": *[_type == "${project().name}"] | order(_createdAt desc) {
		${PROJECT_IN_CARD_FRAGMENT}
	}
`;

export type ProjectListAllFragment = ProjectListAllSchema & {
	_type: 'projectListAll';
	projects: ProjectInCardFragment[];
};

export const projectListFromProjectListAllSection = (
	section: ProjectListAllFragment,
): KeyedArray<Project> => {
	const projects: KeyedArray<Project> = [];

	if (!section.projects?.length) {
		return projects;
	}

	for (const projectInCard of section.projects) {
		if (projectInCard) {
			const project = projectInCardFragmentToProjectDetails(projectInCard);
			if (project) {
				projects.push({
					_key: projectInCard._id,
					...project,
				});
			}
		}
	}

	return projects;
};
