import type {FC} from 'react';
import React from 'react';
import type {FieldError, UseFormRegister} from 'react-hook-form';
import clsx from 'clsx';

import type {FormData} from '../form';

type FormFieldProps = {
	type: string;
	placeholder?: string;
	name: ValidFieldNames;
	label: string;
	register?: UseFormRegister<FormData>;
	error?: FieldError;
	valueAsNumber?: boolean;
	required?: boolean;
};

export type ValidFieldNames = 'name' | 'email' | 'message';

export const FormField: FC<FormFieldProps> = ({
	type,
	placeholder,
	name,
	label,
	register,
	error,
	valueAsNumber,
	required,
}) => {
	if (type === 'textarea') {
		return (
			<div className={clsx('grid')}>
				<label htmlFor={name} className={clsx('font-semibold')}>
					{label}
				</label>
				<textarea
					id={name}
					placeholder={placeholder}
					{...(required && {'aria-required': true})}
					{...(error && {'aria-invalid': true})}
					{...(register && register(name, {valueAsNumber}))}
					className={clsx(
						'rounded',
						'bg-white',
						'focus-visible:focus-visible',
						error ? ['border-red-700', 'border-2'] : 'border-primary-content',
					)}
				/>
				{error && (
					<span role="alert" className={clsx('text-red-700')}>
						{error.message}
					</span>
				)}
			</div>
		);
	}
	return (
		<div className={clsx('grid')}>
			<label htmlFor={name} className={clsx('font-semibold')}>
				{label}
			</label>
			<input
				id={name}
				type={type}
				placeholder={placeholder}
				{...(required && {'aria-required': true})}
				{...(error && {'aria-invalid': true})}
				{...(register && register(name, {valueAsNumber}))}
				className={clsx(
					'rounded',
					'bg-white',
					'focus-visible:focus-visible',
					error ? ['border-red-700', 'border-2'] : 'border-primary-content',
				)}
			/>
			{error && (
				<span role="alert" className={clsx('text-red-700')}>
					{error.message}
				</span>
			)}
		</div>
	);
};
