import type {FC} from 'react';
import clsx from 'clsx';

import type {ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';
import {Heading} from '@/components/typography/heading';
import {Paragraph} from '@/components/typography/paragraph';
import type {KeyedArray} from '@/shared/types';

import type {Project} from '../../shared/types';
import {ProjectCard} from '../project-card';

type Props = {
	title?: string;
	tagline?: string;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	projects: KeyedArray<Project>;
};

export const ProjectList: FC<Props> = ({projects, spacingBottom, spacingTop, title, tagline}) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			{(title || tagline) && (
				<div className={clsx('mb-6')}>
					{title && (
						<Heading level={2} spacing>
							{title}
						</Heading>
					)}
					{tagline && <Paragraph>{tagline}</Paragraph>}
				</div>
			)}

			<ul
				className={clsx('grid', 'grid-cols-1', 'sm:grid-cols-2', 'lg:grid-cols-3', 'gap-6')}
			>
				{projects.map((project) => {
					if (!project) return null;

					return (
						<li key={project._key}>
							<ProjectCard headingLevel={2} project={project} />
						</li>
					);
				})}
			</ul>
		</Container>
	);
};
