import React from 'react';
import {CheckmarkCircleIcon, ExclamationmarkTriangleIcon} from '@navikt/aksel-icons';
import clsx from 'clsx';

type ResponseMessageProps = {
	success: boolean;
	errorMessage: string | null;
	successMessage: string | null;
};

export const ResponseMessage = ({success, errorMessage, successMessage}: ResponseMessageProps) => {
	return (
		<>
			{success && successMessage && (
				<div
					className={clsx(
						'text-green-800',
						'bg-green-50',
						'p-4',
						'border-green-700',
						'border',
						'rounded',
						'flex',
						'gap-1',
					)}
				>
					<CheckmarkCircleIcon aria-hidden="true" fontSize="1.5rem" />
					<span role="status">{successMessage}</span>
				</div>
			)}

			{!success && errorMessage && (
				<div
					className={clsx(
						'text-red-800',
						'p-4',
						'border',
						'rounded',
						'bg-red-50',
						'border-red-700',
						'flex',
						'gap-1',
					)}
				>
					<ExclamationmarkTriangleIcon aria-hidden="true" fontSize="1.5rem" />
					<span role="status">{errorMessage}</span>
				</div>
			)}
		</>
	);
};
