import type {FC} from 'react';

import {ProjectList} from '../../components/project-list';

import {type ProjectListAllFragment, projectListFromProjectListAllSection} from './query';

interface Props {
	data: ProjectListAllFragment;
}

export const ProjectListAllContainer: FC<Props> = ({data}) => {
	const projects = projectListFromProjectListAllSection(data);

	return (
		<ProjectList
			projects={projects}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
