import type {FC} from 'react';
import clsx from 'clsx';

import type {ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';
import {Heading} from '@/components/typography/heading';
import {Paragraph} from '@/components/typography/paragraph';
import type {KeyedArray} from '@/shared/types';

import type {PersonGroup} from '../../shared/types';
import {PersonCard} from '../person-card';

interface Props {
	title?: string;
	tagline?: string;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	groups: KeyedArray<PersonGroup>;
}

const PersonGroupList: FC<Props> = ({title, tagline, spacingBottom, spacingTop, groups}) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			{title && (
				<Heading level={2} spacing>
					{title}
				</Heading>
			)}
			{tagline && <Paragraph>{tagline}</Paragraph>}

			{groups &&
				groups.length > 0 &&
				groups.map((group) => (
					<section key={group._key} className={clsx('mb-8')}>
						{group.title && (
							<Heading level={title ? 3 : 2} spacing size="xlarge">
								{group.title}
							</Heading>
						)}
						<ul
							className={clsx(
								'grid',
								'grid-cols-1',
								'sm:grid-cols-2',
								'lg:grid-cols-3',
								'gap-x-4',
								'gap-y-8',
							)}
						>
							{group.people?.map((person) => {
								return (
									<li key={person._key}>
										<PersonCard person={person} />
									</li>
								);
							})}
						</ul>
					</section>
				))}
		</Container>
	);
};

export default PersonGroupList;
