import type {FC, PropsWithChildren} from 'react';
import React from 'react';
import clsx from 'clsx';

import {Container} from '@/components/container';
import ImageFill from '@/components/image/ImageFill';
import type {ImageWeb} from '@/components/image/types';
import {Heading} from '@/components/typography/heading';
import type {PortableTextOverrides} from '@/sanity/components/portable-text';

type Props = PropsWithChildren & {
	imageWeb?: ImageWeb;
	heading?: string;
};

export const heroWithBgImagePortableTextOverrides: PortableTextOverrides = {
	callToActionLine: 'justify-center',
};

export const HeroWithBgImage: FC<Props> = ({children, imageWeb, heading}) => {
	return (
		<Container>
			<div className={clsx('flex', 'h-[80vh]', 'items-center')}>
				{imageWeb && (
					<div className={clsx('-z-10')}>
						<ImageFill
							priority // Images in page heads are usually part of the LCP
							src={imageWeb.image.src}
							width={imageWeb.image.width}
							height={imageWeb.image.height}
							hotspot={imageWeb.image.hotspot}
							alt={imageWeb.altText ?? ''}
						/>
					</div>
				)}
				<div
					className={clsx(
						'relative',
						'max-w-[450px]',
						'z-20',
						'bg-secondary-light',
						'p-8',
						'rounded',
					)}
				>
					{heading && <Heading spacing>{heading}</Heading>}
					{children}
				</div>
			</div>
		</Container>
	);
};
