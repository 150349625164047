import type {FC} from 'react';
import React from 'react';
import clsx from 'clsx';

import type {ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';

import {Form} from '../form';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
}

export const ContactFormSection: FC<Props> = ({spacingBottom, spacingTop}) => {
	return (
		<Container width="text" spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<div className={clsx('w-full', 'max-w-[350px]', 'mx-auto')}>
				<Form />
			</div>
		</Container>
	);
};
