'use client';

import React, {createRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import axios, {AxiosError} from 'axios';
import clsx from 'clsx';

import {Button} from '@/components/button';
import {Spinner} from '@/components/spinner';

import {FormField, type ValidFieldNames} from '../form-field';
import {ResponseMessage} from '../response-message';

import {FormSchema} from './FormSchema';

// Define the form data structure
export type FormData = {
	name: string;
	email: string;
	message?: string;
};

const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
const recaptchaRef = createRef<ReCAPTCHA>();

export const Form = () => {
	const {
		register,
		handleSubmit,
		formState: {errors, isSubmitting, isSubmitSuccessful},
		setError,
		setValue,
	} = useForm<FormData>({
		resolver: zodResolver(FormSchema),
	});

	const [submitError, setSubmitError] = useState<string | null>(null);

	const onSubmit = async (data: FormData) => {
		const recaptchaToken = await recaptchaRef.current?.executeAsync();

		try {
			setSubmitError(null);
			const response = await axios.post('/api/contact', data, {
				headers: {
					'Content-Type': 'application/json',
					'g-recaptcha-token': recaptchaToken ?? '',
					Accept: 'application/json',
				},
			});

			const {errors = {}} = response.data; // Destructure the 'errors' property from the response data

			// Define a mapping between server-side field names and their corresponding client-side names
			const fieldErrorMapping: Record<string, ValidFieldNames> = {
				name: 'name',
				email: 'email',
				message: 'message',
			};

			// Find the first field with an error in the response data
			const fieldWithError = Object.keys(fieldErrorMapping).find((field) => errors[field]);

			// If a field with an error is found, update the form error state using setError
			if (fieldWithError) {
				// Use the ValidFieldNames type to ensure the correct field names
				setError(fieldErrorMapping[fieldWithError], {
					type: 'server',
					message: errors[fieldWithError],
				});
			}

			// If the response status is 200 and there are no field errors, clear the form values
			if (response.status === 200 && !fieldWithError) {
				setValue('name', '');
				setValue('email', '');
				setValue('message', '');
			}
		} catch (error) {
			setSubmitError('Noe gikk galt, prøv igjen senere');
			if (error instanceof AxiosError) {
				console.error(error.response?.data.message);
			} else {
				console.error(error);
			}
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={clsx('col-auto', 'grid', 'gap-4')}>
				<FormField
					type="text"
					name="name"
					label="Navn"
					register={register}
					error={errors.name}
					required
				/>

				<FormField
					type="email"
					name="email"
					label="Epost"
					register={register}
					error={errors.email}
					required
				/>

				<FormField
					type="textarea"
					name="message"
					label="Din melding"
					register={register}
					error={errors.message}
				/>

				<ResponseMessage
					success={isSubmitSuccessful && !submitError}
					errorMessage={submitError}
					successMessage="Meldingen er sendt"
				/>

				<Button type="submit" disabled={isSubmitting}>
					Send
					{isSubmitting && <Spinner title="sender" role="status" />}
				</Button>
			</div>
			{siteKey && <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} size="invisible" />}
		</form>
	);
};
