import type {FC} from 'react';

import {ProjectList} from '../../components/project-list';

import {
	projectListFromProjectListSelectionSection,
	type ProjectListSelectionFragmentType,
} from './query';

interface Props {
	data: ProjectListSelectionFragmentType;
}

export const ProjectListSelectionContainer: FC<Props> = ({data}) => {
	const projects = projectListFromProjectListSelectionSection(data);

	return (
		<ProjectList
			title={data.title}
			tagline={data.tagline}
			projects={projects}
			spacingTop={data.layout?.spacingTop}
			spacingBottom={data.layout?.spacingBottom}
		/>
	);
};
